var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "system-page", attrs: { "ma-4": "", "d-flex": "" } },
    [
      _c("h1", { attrs: { align: "center" } }, [
        _vm._v("Quote Tool Feb 19 Update"),
      ]),
      _c(
        "rtb-card-body",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-layout",
            {
              staticStyle: { "margin-left": "100px" },
              attrs: { row: "", wrap: "" },
            },
            [
              _c(
                "v-flex",
                { attrs: { xs2: "", "ma-2": "" } },
                [
                  _c("weve-select-field", {
                    attrs: {
                      label: "Events per year",
                      options: _vm.numOfEventsData,
                    },
                    model: {
                      value: _vm.numOfEvents,
                      callback: function ($$v) {
                        _vm.numOfEvents = $$v
                      },
                      expression: "numOfEvents",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs2: "", "ma-2": "" } },
                [
                  _c(
                    "weve-button",
                    {
                      staticStyle: { "margin-top": "25px" },
                      attrs: { size: "sm" },
                      on: { click: _vm.clear },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.numOfEvents, function (numOfEvents, i) {
            return _c(
              "v-layout",
              {
                key: i,
                staticStyle: { "margin-left": "100px" },
                attrs: { row: "", wrap: "" },
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: _vm.numOfPlayersLabel(i),
                        options: _vm.numOfPlayersData,
                      },
                      model: {
                        value: _vm.numOfPlayers[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.numOfPlayers, i, $$v)
                        },
                        expression: "numOfPlayers[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: _vm.eventTypeLabel(i),
                        options: _vm.eventTypeData,
                      },
                      model: {
                        value: _vm.eventType[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.eventType, i, $$v)
                        },
                        expression: "eventType[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: _vm.goldUpgradeLabel(i),
                        options: _vm.goldUpgradeData,
                      },
                      model: {
                        value: _vm.goldUpgrade[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.goldUpgrade, i, $$v)
                        },
                        expression: "goldUpgrade[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: _vm.premiumLabel(i),
                        options: _vm.premiumData,
                      },
                      model: {
                        value: _vm.premium[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.premium, i, $$v)
                        },
                        expression: "premium[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-flex", { attrs: { xs1: "", "ma-4": "" } }, [
                  _vm._v(" Price: "),
                  _c("b", [_vm._v("$" + _vm._s(_vm.pricePerEvent(i)))]),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("v-layout", { staticClass: "details", attrs: { row: "", wrap: "" } }, [
        _c("table", { staticStyle: { "margin-top": "25px" } }, [
          _c("tr", [
            _c("td", { attrs: { width: "300" } }, [_vm._v("Credits Needed:")]),
            _c("td", [_c("b", [_vm._v(" " + _vm._s(_vm.credits))])]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Purchased Separately:")]),
            _c("td", [
              _c("b", [_vm._v("$" + _vm._s(_vm.purchasedSeparatelyPrice))]),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Additional Discount %:")]),
            _c(
              "td",
              [
                _c("weve-select-field", {
                  attrs: { label: "", options: _vm.discountPercentData },
                  model: {
                    value: _vm.discountPercent,
                    callback: function ($$v) {
                      _vm.discountPercent = $$v
                    },
                    expression: "discountPercent",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("$" + _vm._s(_vm.additionalDiscountAmount))]),
          ]),
          _vm.numOfEvents >= 2
            ? _c("tr", [
                _c("td", [_vm._v("Package Price:")]),
                _c("td", [_c("b", [_vm._v("$" + _vm._s(_vm.price) + " ")])]),
              ])
            : _vm._e(),
          _vm.additionalDiscountAmount > 0 && _vm.numOfEvents == 1
            ? _c("tr", [
                _c("td", [_vm._v("Discount Price:")]),
                _c("td", [
                  _c("b", [
                    _vm._v(
                      "$" +
                        _vm._s(
                          _vm.purchasedSeparatelyPrice -
                            _vm.additionalDiscountAmount
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.additionalDiscountAmount > 0 && _vm.numOfEvents > 1
            ? _c("tr", [
                _c("td", [_vm._v("Additional Discount Price:")]),
                _c("td", [
                  _c("b", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.price - _vm.additionalDiscountAmount) +
                        " "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.numOfEvents >= 2
            ? _c("tr", [
                _c("td", [_vm._v("Savings:")]),
                _c("td", [
                  _c("b", [
                    _vm._v(
                      "$" +
                        _vm._s(
                          _vm.purchasedSeparatelyPrice -
                            _vm.price +
                            _vm.additionalDiscountAmount
                        )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.numOfEvents >= 2
            ? _c("tr", [
                _c("td", [_vm._v("Price Per Credit:")]),
                _c("td", [
                  _c("b", [
                    _vm._v(
                      "$" +
                        _vm._s(
                          parseInt(
                            (_vm.price - _vm.additionalDiscountAmount) /
                              this.credits
                          )
                        )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.numOfEvents >= 2
            ? _c("tr", [
                _c("td", [_vm._v("Price Per Person:")]),
                _c("td", [
                  _c("b", [
                    _vm._v(
                      "$" +
                        _vm._s(
                          parseInt(
                            (_vm.price - _vm.additionalDiscountAmount) /
                              _vm.totalPlayers
                          )
                        )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("table", { staticStyle: { "margin-top": "25px", width: "600px" } }, [
          _c("tr", [
            _c("td", { attrs: { align: "left", width: "200" } }, [
              _c("b", [_vm._v("Credits")]),
            ]),
            _c("td", [_c("b", [_vm._v("Price ")])]),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("1")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c1,
                    callback: function ($$v) {
                      _vm.c1 = $$v
                    },
                    expression: "c1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("2")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c2,
                    callback: function ($$v) {
                      _vm.c2 = $$v
                    },
                    expression: "c2",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("3 - 5")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c3,
                    callback: function ($$v) {
                      _vm.c3 = $$v
                    },
                    expression: "c3",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("6 - 10")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c6,
                    callback: function ($$v) {
                      _vm.c6 = $$v
                    },
                    expression: "c6",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("11 - 15")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c11,
                    callback: function ($$v) {
                      _vm.c11 = $$v
                    },
                    expression: "c11",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("16 - 20")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c16,
                    callback: function ($$v) {
                      _vm.c16 = $$v
                    },
                    expression: "c16",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("21 - 25")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c21,
                    callback: function ($$v) {
                      _vm.c21 = $$v
                    },
                    expression: "c21",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("26 - 30")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c26,
                    callback: function ($$v) {
                      _vm.c26 = $$v
                    },
                    expression: "c26",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("31 - 50")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c31,
                    callback: function ($$v) {
                      _vm.c31 = $$v
                    },
                    expression: "c31",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("51 - 75")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c50,
                    callback: function ($$v) {
                      _vm.c50 = $$v
                    },
                    expression: "c50",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("76 - 100")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c75,
                    callback: function ($$v) {
                      _vm.c75 = $$v
                    },
                    expression: "c75",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [_vm._v("Over 100")]
            ),
            _c(
              "td",
              {
                staticStyle: { "font-size": "18px" },
                attrs: { align: "left" },
              },
              [
                _c("weve-text-field", {
                  attrs: { label: "", width: "50px" },
                  model: {
                    value: _vm.c100,
                    callback: function ($$v) {
                      _vm.c100 = $$v
                    },
                    expression: "c100",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "table",
          {
            staticStyle: {
              "margin-top": "25px",
              width: "1000px",
              "font-size": "15px",
            },
          },
          [
            _c("tr", [
              _c("td", [_c("b", [_vm._v("Players")])]),
              _c("td", [_c("b", [_vm._v("Self")])]),
              _c("td", [_c("b", [_vm._v("Virtual")])]),
              _c("td", [_c("b", [_vm._v("On Screen")])]),
              _c("td", [
                _c("b", [
                  _vm._v("Zone 1 (" + _vm._s(_vm.zone1HostCredits) + ")"),
                ]),
              ]),
              _c("td", [
                _c("b", [
                  _vm._v("Zone 2 (" + _vm._s(_vm.zone2HostCredits) + ")"),
                ]),
              ]),
              _c("td", [
                _c("b", [
                  _vm._v("Zone 3 (" + _vm._s(_vm.zone3HostCredits) + ")"),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("10")]),
              _c("td", [_vm._v("$500 (1)")]),
              _c("td", [_vm._v("$399 (1)")]),
              _c("td", [_vm._v("$900 (2)")]),
              _c("td", [
                _vm._v(
                  " $" +
                    _vm._s(_vm.priceCalc(2 + _vm.zone1HostCredits)) +
                    " (" +
                    _vm._s(2 + _vm.zone1HostCredits) +
                    ") "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " $" +
                    _vm._s(_vm.priceCalc(2 + _vm.zone2HostCredits)) +
                    " (" +
                    _vm._s(2 + _vm.zone2HostCredits) +
                    ") "
                ),
              ]),
              _c("td", [_vm._v("$3850 (11)")]),
            ]),
            _vm._l(_vm.numOfPlayerLevels, function (level, i) {
              return _c("tr", { key: i }, [
                _c("td", [_vm._v(" " + _vm._s((i + 1) * 25) + " ")]),
                _c("td", [
                  _vm._v(
                    "$" +
                      _vm._s(_vm.selfPriceCalc(i + 1)) +
                      " (" +
                      _vm._s(_vm.selfCreditCalc(i + 1)) +
                      ")"
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    "$" +
                      _vm._s(_vm.priceCalc(i + 1)) +
                      " (" +
                      _vm._s(i + 1) +
                      ")"
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " $" +
                      _vm._s(_vm.onScreenPriceCalc(i + 3)) +
                      " (" +
                      _vm._s(_vm.onScreenCreditCalc(i + 3)) +
                      ") "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " $" +
                      _vm._s(
                        _vm.priceCalc((i + 1) * 2 + _vm.zone1HostCredits)
                      ) +
                      " (" +
                      _vm._s((i + 1) * 2 + _vm.zone1HostCredits) +
                      ") "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " $" +
                      _vm._s(
                        _vm.priceCalc((i + 1) * 2 + _vm.zone2HostCredits)
                      ) +
                      " (" +
                      _vm._s((i + 1) * 2 + _vm.zone2HostCredits) +
                      ") "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " $" +
                      _vm._s(
                        _vm.priceCalc(_vm.zone3HostCreditsCalc((i + 1) * 2))
                      ) +
                      " (" +
                      _vm._s(_vm.zone3HostCreditsCalc((i + 1) * 2)) +
                      ") "
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }