<template>
  <rtb-card ma-4 d-flex class="system-page">
    <h1 align="center">Quote Tool Feb 19 Update</h1>
    <rtb-card-body wrap>
      <v-layout row wrap style="margin-left: 100px">
        <v-flex xs2 ma-2>
          <weve-select-field
            label="Events per year"
            v-model="numOfEvents"
            :options="numOfEventsData"
          />
        </v-flex>
        <v-flex xs2 ma-2>
          <weve-button style="margin-top: 25px" size="sm" @click="clear"
            >Reset</weve-button
          >
        </v-flex>
        <!-- <v-flex ma-2 mt-5>
        *One free gold event for every 4 events booked
        </v-flex> -->
      </v-layout>
      <v-layout
        row
        wrap
        style="margin-left: 100px"
        v-for="(numOfEvents, i) in numOfEvents"
        :key="i"
      >
        <v-flex xs2 ma-2>
          <weve-select-field
            :label="numOfPlayersLabel(i)"
            v-model="numOfPlayers[i]"
            :options="numOfPlayersData"
          />
        </v-flex>
        <v-flex xs2 ma-2>
          <weve-select-field
            :label="eventTypeLabel(i)"
            v-model="eventType[i]"
            :options="eventTypeData"
          />
        </v-flex>

        <v-flex xs2 ma-2>
          <weve-select-field
            :label="goldUpgradeLabel(i)"
            v-model="goldUpgrade[i]"
            :options="goldUpgradeData"
          />
        </v-flex>
        <v-flex xs2 ma-2>
          <weve-select-field
            :label="premiumLabel(i)"
            v-model="premium[i]"
            :options="premiumData"
          />
        </v-flex>
        <v-flex xs1 ma-4>
          Price: <b>${{ pricePerEvent(i) }}</b>
        </v-flex>
      </v-layout>
    </rtb-card-body>
    <v-layout row wrap class="details">
      <table style="margin-top: 25px">
        <tr>
          <td width="300">Credits Needed:</td>
          <td>
            <b> {{ credits }}</b>
          </td>
        </tr>
        <tr>
          <td>Purchased Separately:</td>
          <td>
            <b>${{ purchasedSeparatelyPrice }}</b>
          </td>
        </tr>
        <tr>
          <td>Additional Discount %:</td>
          <td>
            <weve-select-field
              label=""
              v-model="discountPercent"
              :options="discountPercentData"
            />
          </td>
          <td>${{ additionalDiscountAmount }}</td>
        </tr>
        <tr v-if="numOfEvents >= 2">
          <td>Package Price:</td>
          <td>
            <b>${{ price }} </b>
          </td>
        </tr>
        <tr v-if="additionalDiscountAmount > 0 && numOfEvents == 1">
          <td>Discount Price:</td>
          <td>
            <b>${{ purchasedSeparatelyPrice - additionalDiscountAmount }} </b>
          </td>
        </tr>
        <tr v-if="additionalDiscountAmount > 0 && numOfEvents > 1">
          <td>Additional Discount Price:</td>
          <td>
            <b>${{ price - additionalDiscountAmount }} </b>
          </td>
        </tr>
        <tr v-if="numOfEvents >= 2">
          <td>Savings:</td>
          <td>
            <b
              >${{
                purchasedSeparatelyPrice - price + additionalDiscountAmount
              }}</b
            >
          </td>
        </tr>

        <tr v-if="numOfEvents >= 2">
          <td>Price Per Credit:</td>
          <td>
            <b
              >${{
                parseInt((price - additionalDiscountAmount) / this.credits)
              }}</b
            >
          </td>
        </tr>
        <tr v-if="numOfEvents >= 2">
          <td>Price Per Person:</td>
          <td>
            <b
              >${{
                parseInt((price - additionalDiscountAmount) / totalPlayers)
              }}</b
            >
          </td>
        </tr>
      </table>
      <table style="margin-top: 25px; width: 600px">
        <tr>
          <td align="left" width="200"><b>Credits</b></td>
          <td><b>Price </b></td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">1</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c1" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">2</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c2" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">3 - 5</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c3" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">6 - 10</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c6" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">11 - 15</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c11" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">16 - 20</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c16" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">21 - 25</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c21" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">26 - 30</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c26" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">31 - 50</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c31" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">51 - 75</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c50" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">76 - 100</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c75" width="50px" />
          </td>
        </tr>
        <tr>
          <td align="left" style="font-size: 18px">Over 100</td>
          <td align="left" style="font-size: 18px">
            <weve-text-field label="" v-model="c100" width="50px" />
          </td>
        </tr>
      </table>
      <table style="margin-top: 25px; width: 1000px; font-size: 15px">
        <tr>
          <td><b>Players</b></td>
          <td><b>Self</b></td>
          <td><b>Virtual</b></td>
          <td><b>On Screen</b></td>
          <td>
            <b>Zone 1 ({{ zone1HostCredits }})</b>
          </td>
          <td>
            <b>Zone 2 ({{ zone2HostCredits }})</b>
          </td>
          <td>
            <b>Zone 3 ({{ zone3HostCredits }})</b>
          </td>
        </tr>
        <tr>
          <td>10</td>
          <td>$500 (1)</td>
          <td>$399 (1)</td>
          <td>$900 (2)</td>
          <td>
            ${{ priceCalc(2 + zone1HostCredits) }} ({{ 2 + zone1HostCredits }})
          </td>
          <td>
            ${{ priceCalc(2 + zone2HostCredits) }} ({{ 2 + zone2HostCredits }})
          </td>
          <td>$3850 (11)</td>
        </tr>
        <tr v-for="(level, i) in numOfPlayerLevels" :key="i">
          <td>
            {{ (i + 1) * 25 }}
          </td>
          <td>${{ selfPriceCalc(i + 1) }} ({{ selfCreditCalc(i + 1) }})</td>
          <td>${{ priceCalc(i + 1) }} ({{ i + 1 }})</td>
          <td>
            ${{ onScreenPriceCalc(i + 3) }} ({{ onScreenCreditCalc(i + 3) }})
          </td>
          <td>
            ${{ priceCalc((i + 1) * 2 + zone1HostCredits) }} ({{
              (i + 1) * 2 + zone1HostCredits
            }})
          </td>
          <td>
            ${{ priceCalc((i + 1) * 2 + zone2HostCredits) }} ({{
              (i + 1) * 2 + zone2HostCredits
            }})
          </td>
          <td>
            ${{ priceCalc(zone3HostCreditsCalc((i + 1) * 2)) }} ({{
              zone3HostCreditsCalc((i + 1) * 2)
            }})
          </td>
        </tr>
      </table>
    </v-layout>
  </rtb-card>
</template>

<script>
import {
  WeveButton,
  WeveForm,
  WeveSelectField,
  WeveSlider,
  WeveSpinbutton,
  WeveTextField,
  WeveToggle
} from "@weve/ui"
import { RtbCard, RtbCardBody } from "@/components/ui"

export default {
  name: "FinnPricing",
  components: {
    WeveTextField,
    WeveButton,
    WeveSpinbutton,
    WeveToggle,
    WeveSlider,
    WeveForm,
    WeveSelectField,
    RtbCard,
    RtbCardBody
  },
  data() {
    return {
      numOfPlayerLevels: 40,
      eventType: [
        "On Site Zone 1",
        "IRL / Hybrid On Screen",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual"
      ],
      c1: 499,
      c2: 450,
      c3: 400,
      c6: 380,
      c11: 350,
      c16: 325,
      c21: 310,
      c26: 290,
      c31: 275,
      c50: 250,
      c75: 225,
      c100: 210,
      eventTypeData: [
        "Self-Hosted",
        "Virtual",
        "IRL / Hybrid On Screen",
        "On Site Zone 1",
        "On Site Zone 2",
        "On Site Zone 3"
      ],
      numOfPlayersData: [
        25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375,
        400, 425, 450, 475, 500, 525, 550, 575, 600, 625, 650, 675, 700, 725,
        750, 775, 800, 825, 850, 875, 900, 925, 950, 1000
      ],
      proUpgrades: [],
      numOfPlayers: [
        25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25
      ],
      cadenceData: ["Quarterly", "Monthly"],
      cadence: "Quarterly",
      inPersonHosts: 0,
      zone1HostCredits: 4,
      zone2HostCredits: 7,
      zone3HostCredits: 10,
      zone4HostCredits: 10,
      proVirtualCredits: 1,
      premiumVirtualCredits: 2,
      proCredits: 2,
      premiumCredits: 4,
      inPersonHostsData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      IRLEvents: 1,
      IRLEventsData: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 50, 75, 100
      ],
      virtualEvents: 3,
      virtualEventsData: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 50, 75, 100
      ],
      priceMatrix: [{ 1: 500, 2: 350 }],
      premiumData: ["Standard", "Pro", "Premium"],

      numOfEvents: 4,
      numOfEventsData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      virtualCredits: 0,
      IRLCredits: 0,
      discountPercent: 0,
      discountPercentData: [0, 5, 10, 15, 20],
      goldUpgrade: [
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No"
      ],
      premium: [
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard"
      ],
      goldUpgradeCredits: 3,
      goldUpgradeData: ["No", "Yes"],
      onSiteHost: [
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen"
      ],
      onSiteHostData: [
        "On Screen",
        "On Site Zone 1",
        "On Site Zone 2",
        "On Site Zone 3",
        "On Site Zone 4"
      ]
    }
  },
  methods: {
    clear() {
      location.reload()
    },
    priceCalc(c) {
      var t = 0
      t = this.pricePerCredit(c) * c
      return t
    },
    zone3HostCreditsCalc(c) {
      if (c == 2) return 11
      if (c == 4) return 13
      if (c == 6) return 14
      if (c == 8) return 15
      return c + this.zone3HostCredits
    },
    onScreenPriceCalc(c) {
      var t = 0

      t = this.pricePerCredit(c) * c
      return t
    },
    onScreenCreditCalc(c) {
      // if (c > 4) c = c -1
      return c
    },
    selfPriceCalc(c) {
      if (c == 1) return 750
      const selfCredits = c
      return parseInt(this.pricePerCredit(selfCredits)) * selfCredits
    },
    selfCreditCalc(c) {
      return c
    },
    getGold(c) {
      var t = 0
      t = this.pricePerCredit(c)
      return t
    },
    getPremium(c) {
      var t = 0
      t = this.pricePerCredit(c)
      return t * 5
    },
    pricePerEvent(i) {
      var c = 0
      if (this.eventType[i] == "Virtual") {
        c = c + this.numOfPlayers[i] / 25
        if (this.goldUpgrade[i] == "Yes") c = c + 1
        if (this.premium[i] == "Pro") c = c + this.proVirtualCredits
        if (this.premium[i] == "Premium") c = c + this.premiumVirtualCredits
        return this.pricePerCredit(c) * c
      } else if (this.eventType[i] == "Self-Hosted") {
        c = c + this.numOfPlayers[i] / 25
        console.log("SELF-HOSTED")
        var premiumPrice = 0
        if (this.premium[i] == "Pro")
          premiumPrice =
            this.pricePerCredit(c + this.proCredits) * this.proCredits
        if (this.premium[i] == "Premium")
          premiumPrice =
            this.pricePerCredit(c + this.premiumCredits) * this.premiumCredits
        return this.selfPriceCalc(c) + premiumPrice
      } else if (this.eventType[i] == "IRL / Hybrid On Screen") {
        c = c + this.numOfPlayers[i] / 25 + 2
      } else if (this.eventType[i] == "On Site Zone 1") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone1HostCredits
      } else if (this.eventType[i] == "On Site Zone 2") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone2HostCredits
      } else if (this.eventType[i] == "On Site Zone 3") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone3HostCredits
        if (this.numOfPlayers[i] == 10) c = 11
        if (this.numOfPlayers[i] == 25) c = 11
        if (this.numOfPlayers[i] == 50) c = 13
        if (this.numOfPlayers[i] == 75) c = 14
        if (this.numOfPlayers[i] == 100) c = 15
      } else if (this.eventType[i] == "On Site Zone 4") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone4HostCredits
      }
      if (this.goldUpgrade[i] == "Yes") c = c + this.goldUpgradeCredits
      console.log("EVENT TYPE", this.eventType[i])
      console.log("PREMIUM", this.premium[i])
      if (this.premium[i] == "Pro") c = c + this.proCredits
      if (this.premium[i] == "Premium") c = c + this.premiumCredits
      return this.pricePerCredit(c) * c
    },
    numOfPlayersLabel(i) {
      var b = i + 1
      return "Participants for Event " + b
    },
    premiumLabel(i) {
      var c = 0
      if (this.premium[i] == "Standard") {
        return "Pro / Premium Option"
      } else if (this.premium[i] == "Pro") {
        if (this.eventType[i] == "Virtual") {
          return "Pro (" + this.proVirtualCredits + " credits" + ")"
        } else {
          return "Pro (" + this.proCredits + " credits" + ")"
        }
      } else {
        if (this.eventType[i] == "Virtual") {
          return "Premium (" + this.premiumVirtualCredits + " credits" + ")"
        } else {
          return "Premium (" + this.premiumCredits + " credits" + ")"
        }
      }
    },
    eventTypeLabel(i) {
      var c = 0
      if (this.eventType[i] == "Virtual") {
        c = this.numOfPlayers[i] / 25
      } else if (this.eventType[i] == "Self-Hosted") {
        c = c + this.numOfPlayers[i] / 25
        console.log("SELF HOSTED C")
        c = this.selfCreditCalc(c)
      } else if (this.eventType[i] == "IRL / Hybrid On Screen") {
        c = this.numOfPlayers[i] / 25 + 2
      } else if (this.eventType[i] == "On Site Zone 1") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone1HostCredits
      } else if (this.eventType[i] == "On Site Zone 2") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone2HostCredits
      } else if (this.eventType[i] == "On Site Zone 3") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone3HostCredits
        if (this.numOfPlayers[i] == 10) c = 11
        if (this.numOfPlayers[i] == 25) c = 11
        if (this.numOfPlayers[i] == 50) c = 13
        if (this.numOfPlayers[i] == 75) c = 14
        if (this.numOfPlayers[i] == 100) c = 15
      } else if (this.eventType[i] == "On Site Zone 4") {
        c = c + (this.numOfPlayers[i] / 25) * 2
        c = c + this.zone3HostCredits
        if (this.numOfPlayers[i] == 10) c = 11
        if (this.numOfPlayers[i] == 25) c = 11
        if (this.numOfPlayers[i] == 50) c = 13
        if (this.numOfPlayers[i] == 75) c = 14
        if (this.numOfPlayers[i] == 100) c = 15
      }
      return "Format (" + c + " credits" + ")"
    },
    onSiteHostLabel(i) {
      var c = 0
      if (this.onSiteHost[i] != "No") {
        if (this.onSiteHost[i] == "IRL / Hybrid On Screen") c = c + 2
        if (this.onSiteHost[i] == "On Site Zone 1") c = this.zone1HostCredits
        if (this.onSiteHost[i] == "On Site Zone 2") c = this.zone2HostCredits
        if (this.onSiteHost[i] == "On Site Zone 3") c = this.zone3HostCredits
        if (this.onSiteHost[i] == "On Site Zone 4") c = this.zone4HostCredits
        return "Host Type" + " (" + c + " credits)"
      } else {
        return "Host Type"
      }
    },
    pricePerCredit(c) {
      if (c == 1) return this.c1
      if (c == 2) return this.c2
      if (c >= 3 && c < 6) return this.c3
      if (c >= 6 && c < 11) return this.c6
      if (c >= 11 && c < 16) return this.c11
      if (c >= 16 && c < 21) return this.c16
      if (c >= 21 && c < 26) return this.c21
      if (c >= 26 && c < 31) return this.c26
      if (c >= 31 && c < 51) return this.c31
      if (c >= 51 && c < 76) return this.c50
      if (c >= 76 && c < 101) return this.c75
      if (c >= 101) return this.c100
    },
    goldUpgradeLabel(i) {
      var c = 0
      if (this.goldUpgrade[i] == "Yes") {
        if (this.eventType[i] == "Virtual") {
          c = 1
          return "Gold Upgrade" + " (" + c + " credits)"
        } else {
          c = this.goldUpgradeCredits
          return "Gold Upgrade" + " (" + c + " credits)"
        }
      } else {
        return "Gold Upgrade"
      }
    }
  },
  computed: {
    i() {
      return this.numOfPlayersData.indexOf(this.numOfPlayers)
    },
    additionalDiscountAmount() {
      return this.price * (this.discountPercent * 0.01)
    },
    purchasedSeparatelyPrice() {
      var p = 0
      console.log("NUMBER OF EVENTS", this.numOfEvents)
      for (let i = 0; i < this.numOfEvents; i++) {
        p = p + this.pricePerEvent(i)
      }
      return p
    },
    credits() {
      var c = 0
      // console.log("FUN", this.numOfEvents)
      var selfCredits = 0
      for (let i = 0; i < this.numOfEvents; i++) {
        if (this.eventType[i] == "Virtual") {
          c = c + this.numOfPlayers[i] / 25
        } else if (this.eventType[i] == "Self-Hosted") {
          selfCredits = this.numOfPlayers[i] / 25
          c = c + this.selfCreditCalc(selfCredits)
        } else if (this.eventType[i] == "IRL / Hybrid On Screen") {
          c = c + this.numOfPlayers[i] / 25 + 2
        } else if (this.eventType[i] == "On Site Zone 1") {
          c = c + (this.numOfPlayers[i] / 25) * 2
          c = c + this.zone1HostCredits
        } else if (this.eventType[i] == "On Site Zone 2") {
          c = c + (this.numOfPlayers[i] / 25) * 2
          c = c + this.zone2HostCredits
        } else if (this.eventType[i] == "On Site Zone 3") {
          if (this.numOfPlayers[i] == 10) c = c + 11
          if (this.numOfPlayers[i] == 25) c = c + 11
          if (this.numOfPlayers[i] == 50) c = c + 13
          if (this.numOfPlayers[i] == 75) c = c + 14
          if (this.numOfPlayers[i] == 100) c = c + 15
          if (this.numOfPlayers[i] > 100) {
            c = c + (this.numOfPlayers[i] / 25) * 2
            c = c + this.zone3HostCredits
          }
        }

        if (this.goldUpgrade[i] == "Yes" && this.eventType[i] != "Virtual")
          c = c + this.goldUpgradeCredits
        if (this.goldUpgrade[i] == "Yes" && this.eventType[i] == "Virtual")
          c = c + 1
        if (this.premium[i] == "Pro") {
          if (this.eventType[i] == "Virtual") c = c + this.proVirtualCredits
          if (this.eventType[i] != "Virtual") c = c + this.proCredits
        }
        if (this.premium[i] == "Premium") {
          if (this.eventType[i] == "Virtual") c = c + this.premiumVirtualCredits
          if (this.eventType[i] != "Virtual") c = c + this.premiumCredits
        }
      }
      return c
    },
    goldCredits() {
      return parseInt(this.credits / 5)
    },
    totalPlayers() {
      var p = 0
      for (let n = 0; n < this.numOfEvents; n++) {
        p = p + this.numOfPlayers[n]
      }
      return p
    },
    pricePerPerson() {
      var i = this.totalPlayers
      return parseInt(this.price / i)
    },
    totalSavings() {
      return this.totalValue - this.price
    },
    totalValue() {
      return 500 * this.credits
    },
    price() {
      return parseInt(this.credits * this.pricePerCredit(this.credits))
    },
    discountCalc() {
      return parseInt(((this.totalValue - this.price) / this.totalValue) * 100)
    }
  }
}
</script>
<style>
.system-page {
  color: white;
  background-color: black;
}
.details {
  background-color: black;
  width: 60%;
  font-size: 24px;
  margin-right: auto;
  margin-left: auto;
}
.vs__selected {
  color: #fff !important;
}
</style>
